<template>
  <div class="page-slideout">
    <div class="section section-separate">
      <div class="container">
        <form class="content register-content">
          <div class="nav-responsive">
            <ul class="nav nav-register">
              <template v-for="(item, index) in itemsStep">
                <li
                  :key="index"
                  :class="
                    (item.active && 'active') ||
                    (item.finish && 'navtop-canclick')
                  "
                  @click="
                    item.finish &&
                      stepwh_signup < 8 &&
                      changeStep(item.linkItems)
                  "
                >
                  <a href="" class="d-flex flex-column">
                    <span v-if="item.finish" class="icons"
                      ><img src="@/assets/icons/icon-checkmark.svg" alt=""
                    /></span>
                    <span class="text">{{ item.name }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </div>
          <div class="scroll-wrapper scrollbar-inner has-menu overflow-y-auto">
            <div class="form-box w-630">
              <div class="form-group">
                <h3 class="title-md mb-2">{{ $t("SignupStep6.title") }}</h3>
                <label class="light f20 mb-0">{{
                  $t("SignupStep6.title_policy")
                }}</label>
              </div>
            </div>

            <div class="form-box w-630">
              <h3 class="f20 mb-4">{{ $t("SignupStep6.title_reserve") }}</h3>
              <div class="form-group">
                <label class="light pb-2">{{
                  $t("SignupStep6.label_reserve")
                }}</label>
                <div
                  class="dropdown select"
                  @click="getfocus('amountday')"
                  @mouseleave="mouseOver('amountday')"
                >
                  <a
                    data-toggle="dropdown"
                    data-display="static"
                    id="amountday"
                  >
                    <v-select
                      v-model="addPolicyData.amountday"
                      :items="itemsAmountDay"
                      label=""
                      :placeholder="$t('SignupStep6.place_reserve_day')"
                      :no-data-text="$t('SignupStep.nodata')"
                      :suffix="$t('SignupStep6.daybeforecheckin')"
                      color="pink"
                      solo
                      flat
                      dense
                      single-line
                      hide-details
                    >
                      <template v-slot:item="data">
                        <v-list-item-content
                          @mouseenter="getfocus('amountday')"
                          @mouseleave="mouseOver('amountday')"
                        >
                          <v-list-item-title
                            v-html="data.item"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-select>
                  </a>
                </div>
              </div>

              <div class="box-condition">
                <div class="d-flex">
                  <h3>{{ $t("SignupStep6.title_unintentional") }}</h3>

                  <label class="switch-item">
                    <input type="checkbox" v-model="addPolicyData.protect" />
                    <span class="slider round"></span>
                  </label>
                </div>
                <p>{{ $t("SignupStep6.label_unintentional") }}</p>
              </div>
            </div>

            <div class="form-box w-630">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="light"
                      ><span class="medium">{{
                        $t("SignupStep6.title_checkin")
                      }}</span>
                      {{ $t("SignupStep6.until_checkin") }}</label
                    >
                    <div
                      class="dropdown select"
                      @click="getfocus('checkin')"
                      @mouseleave="mouseOver('checkin')"
                    >
                      <a
                        data-toggle="dropdown"
                        data-display="static"
                        id="checkin"
                      >
                        <v-select
                          v-model="addPolicyData.checkin"
                          :items="itemsCheckinTime"
                          label=""
                          :placeholder="$t('SignupStep6.place_select_time')"
                          :no-data-text="$t('SignupStep.nodata')"
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                        >
                          <template v-slot:item="data">
                            <v-list-item-content
                              @mouseenter="getfocus('checkin')"
                              @mouseleave="mouseOver('checkin')"
                            >
                              <v-list-item-title
                                v-html="data.item"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="light"
                      ><span class="medium">{{
                        $t("SignupStep6.title_checkout")
                      }}</span>
                      {{ $t("SignupStep6.until_checkout") }}</label
                    >
                    <div
                      class="dropdown select"
                      @click="getfocus('checkout')"
                      @mouseleave="mouseOver('checkout')"
                    >
                      <a
                        data-toggle="dropdown"
                        data-display="static"
                        id="checkout"
                      >
                        <v-select
                          v-model="addPolicyData.checkout"
                          :items="itemsCheckoutTime"
                          label=""
                          :placeholder="$t('SignupStep6.place_select_time')"
                          :no-data-text="$t('SignupStep.nodata')"
                          color="pink"
                          solo
                          flat
                          dense
                          single-line
                          hide-details
                        >
                          <template v-slot:item="data">
                            <v-list-item-content
                              @mouseenter="getfocus('checkout')"
                              @mouseleave="mouseOver('checkout')"
                            >
                              <v-list-item-title
                                v-html="data.item"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-box w-630">
              <div class="form-group d-block">
                <label class="d-block medium">{{
                  $t("SignupStep6.title_child")
                }}</label>
                <label class="d-block light">
                  {{ $t("SignupStep6.label_child1") }}<br />
                  <span class="f14">{{ $t("SignupStep6.label_child2") }}</span>
                </label>

                <div class="row">
                  <div class="col-md-6">
                    <div class="button-answer nav">
                      <button
                        class="btn btn-outline hover-pink"
                        :class="
                          addPolicyData.allowchild && 'btn-outline-active'
                        "
                        data-toggle="tab"
                        type="button"
                        @click="addPolicyData.allowchild = true"
                      >
                        <span>{{ $t("SignupStep6.btn_yes") }}</span>
                      </button>
                      <button
                        class="btn btn-outline hover-pink"
                        :class="
                          !addPolicyData.allowchild && 'btn-outline-active'
                        "
                        data-toggle="tab"
                        type="button"
                        @click="addPolicyData.allowchild = false"
                      >
                        <span>{{ $t("SignupStep6.btn_no") }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group d-block">
                <label class="d-block medium">{{
                  $t("SignupStep6.title_pet")
                }}</label>
                <label class="d-block light">
                  {{ $t("SignupStep6.label_pet1") }}
                </label>

                <div class="row">
                  <div class="col-md-6">
                    <div class="button-answer nav">
                      <button
                        class="btn btn-outline hover-pink"
                        :class="addPolicyData.allowpet && 'btn-outline-active'"
                        data-toggle="tab"
                        type="button"
                        @click="addPolicyData.allowpet = true"
                      >
                        <span>{{ $t("SignupStep6.btn_yes") }}</span>
                      </button>
                      <button
                        class="btn btn-outline hover-pink"
                        :class="!addPolicyData.allowpet && 'btn-outline-active'"
                        data-toggle="tab"
                        type="button"
                        @click="addPolicyData.allowpet = false"
                      >
                        <span>{{ $t("SignupStep6.btn_no") }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-buttons">
            <div
              class="pa-0 d-flex flex-row align-center justify-space-between flex-100per"
            >
              <button
                type="button"
                class="btn-back gl-bt hover_btn ml-0"
                style="text-transform: capitalize !important"
                @click="goback()"
              >
                <span>{{ `< ${$t("servicepage.back")}` }}</span>
              </button>

              <button
                type="button"
                class="btn xs gl-bt hover_btn mx-0"
                style="text-transform: capitalize !important"
                :disabled="!cangonextstep"
                @click="nextToPaid(7)"
              >
                <span>{{ $t("SignupStep6.btn_next") }}</span>
              </button>
            </div>
          </div>
        </form>
        <div class="sidebar">
          <div class="inner d-flex">
            <a
              :href="pathrightphoto"
              class="img-exemple iphone-model d-flex flex-column align-center justify-center"
              data-fancybox
            >
              <span class="btn-open-picture">{{
                $t("SignupStep4.clickopenpicture")
              }}</span>
              <img :src="pathrightphoto" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "PolicyScreen",
  metaInfo: {
    title: "Policy",
    titleTemplate: "%s | Aigent",
  },
  data: () => ({
    stepwh_signup: 6,
    addPolicyData: {
      amountday: "",
      protect: true,
      checkin: "",
      checkout: "",
      allowchild: true,
      allowpet: true,
    },
    itemsAmountDay: [1, 3, 5, 7, 15, 30],
    itemsCheckinTime: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    itemsCheckoutTime: [
      "00:00",
      "01:00",
      "02:00",
      "03:00",
      "04:00",
      "05:00",
      "06:00",
      "07:00",
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
      "23:00",
    ],
    propertyID: "",
    cangonextstep: false,
    value_element: "",
    itemsStep: [],
    pathrightphoto: "",
    userToken: "",
    itemsRatePlan: [],
    itemsDefaultRatePlan: [],
    itemsRatePlanData: [],
    roomTypies: [],
    itemsRoomType: [],
    itemsDefaultRoomType: [],
  }),
  created() {
    const self = this;
    self.userToken = self.$route.params.userToken;
    self.propertyID = self.$route.params.propertyId;
    if (self.userToken && self.propertyID) {
      self.initStepData();
    } else {
      self.gotosignin();
    }
    if (
      (localStorage.getItem("Token") == "" || !localStorage.getItem("Token")) &&
      self.userToken
    ) {
      localStorage.setItem("Token", self.userToken);
    }
    localStorage.setItem("signupstep", self.stepwh_signup);
    localStorage.setItem("SignupStep", self.stepwh_signup);
  },
  mounted() {
    EventBus.$on("refreshRightPhoto", this.initStepData);
    this.colorHover();
  },
  watch: {
    "addPolicyData.amountday"() {
      const self = this;
      self.checkcangonext();
    },
    "addPolicyData.protect"() {
      const self = this;
      self.checkcangonext();
    },
    "addPolicyData.checkin"() {
      const self = this;
      self.checkcangonext();
    },
    "addPolicyData.checkout"() {
      const self = this;
      self.checkcangonext();
    },
    "addPolicyData.allowchild"() {
      const self = this;
      self.checkcangonext();
    },
    "addPolicyData.allowpet"() {
      const self = this;
      self.checkcangonext();
    },
  },
  methods: {
    colorHover() {
      const button = document.querySelector(".hover_btn");
      button.addEventListener("mousemove", (e) => {
        const { x, y } = button.getBoundingClientRect();
        button.style.setProperty("--x", e.clientX - x);
        button.style.setProperty("--y", e.clientY - y);
      });
    },
    getfocus(val) {
      var element = document.getElementById(val);
      if (this.value_element === "") {
        element.classList.add("border-pink-focus");
        this.value_element = val;
      } else if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.add("border-pink-focus");
        this.value_element = val;
      }
    },
    mouseOver(val) {
      var element = document.getElementById(val);
      if (this.value_element) {
        document
          .getElementById(this.value_element)
          .classList.remove("border-pink-focus");
        element.classList.remove("border-pink-focus");
        this.value_element = "";
      }
    },
    gotosignin() {
      var item = { link: "signin", name: "SigninScreen" };
      EventBus.$emit("changePathname", item);
    },
    checkcangonext() {
      const self = this;
      // console.log(self.addPolicyData)
      if (
        self.addPolicyData.amountday &&
        self.addPolicyData.checkin &&
        self.addPolicyData.checkout
      ) {
        self.cangonextstep = true;
      } else self.cangonextstep = false;
    },
    async initRatePlanTypes() {
      const self = this;
      var temp = [];
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + "/setting/rate_plan",
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          res?.data?.result?.map((el) => {
            temp.push({
              _id: el._id,
              name: el.value,
              value: el._id,
            });
          });
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      } finally {
        self.itemsDefaultRatePlan = temp;
        // console.log('itemsDefaultRatePlan: ', self.itemsDefaultRatePlan)
      }
    },
    checkcanuserateplanFunc() {
      const self = this;
      self.itemsRatePlan = [];
      var temp = [];
      if (self?.itemsRatePlanData?.length) {
        self?.itemsDefaultRatePlan?.map((el) => {
          const checkRPInUse = self.itemsRatePlanData.find(
            (p) => p._id == self.rateplanID
          );
          const checkRP = self.itemsRatePlanData.filter(
            (p) => p.rate_plan == el.value
          );
          if (checkRP?.length < 2) {
            temp.push(el);
          }
          if (checkRPInUse?._id) {
            temp.push(el);
          }
        });
      } else {
        temp = self.itemsDefaultRatePlan;
      }
      self.itemsRatePlan = temp;
    },
    async initStepData() {
      const self = this;
      var temp = {};
      var tempPolicy = {};
      EventBus.$emit("loadingPage");
      try {
        const res = await self.axios.get(
          process.env.VUE_APP_API + `/property?_id=${self.propertyID}`,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          tempPolicy = res?.data?.result[0];
          self.roomTypies = tempPolicy.rooms;
          // console.log('tempPolicy: ', tempPolicy)
          res?.data?.result?.map((el) => {
            if (el._id == self.propertyID) temp = el;
          });
          self.initDataStep = temp;
          self.keepUserID = res?.data?.result[0]?.user?._id;
          // console.log(self.keepUserID)
          if (res?.data?.result?.length) {
            self.addPolicyData = {
              amountday: tempPolicy?.book_in_advance || 0,
              protect: tempPolicy?.book_in_unintentional || false,
              checkin: tempPolicy?.check_in || "14:00",
              checkout: tempPolicy?.check_out || "12:00",
              allowchild: tempPolicy?.child || false,
              allowpet: tempPolicy?.pet || false,
            };
            self.itemsStep = [
              {
                name: self.$t("SignupStep.step1"),
                active: (self.stepwh_signup == 1 && true) || false,
                finish: temp?.step_signup?.step1 || false,
                linkItems: {
                  link: `signup/basic/${self.userToken}/${self.propertyID}`,
                  name: "SignupBasicInformationScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 1,
              },
              {
                name: self.$t("SignupStep.step2"),
                active: (self.stepwh_signup == 2 && true) || false,
                finish: temp?.step_signup?.step2 || false,
                linkItems: {
                  link: `signup/room/${self.userToken}/${self.propertyID}`,
                  name: "SignupRoomScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 2,
              },
              {
                name: self.$t("SignupStep.step3"),
                active: (self.stepwh_signup == 3 && true) || false,
                finish: temp?.step_signup?.step3 || false,
                linkItems: {
                  link: `signup/rateplan/${self.userToken}/${self.propertyID}`,
                  name: "SignupRatePlanScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 3,
              },
              {
                name: self.$t("SignupStep.step4"),
                active: (self.stepwh_signup == 4 && true) || false,
                finish: temp?.step_signup?.step4 || false,
                linkItems: {
                  link: `signup/facilities/${self.userToken}/${self.propertyID}`,
                  name: "SignupFacilitiesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 4,
              },
              {
                name: self.$t("SignupStep.step5"),
                active: (self.stepwh_signup == 5 && true) || false,
                finish: temp?.step_signup?.step5 || false,
                linkItems: {
                  link: `signup/pictures/${self.userToken}/${self.propertyID}`,
                  name: "SignupPicturesScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 5,
              },
              {
                name: self.$t("SignupStep.step6"),
                active: (self.stepwh_signup == 6 && true) || false,
                finish: temp?.step_signup?.step6 || false,
                linkItems: {
                  link: `signup/policy/${self.userToken}/${self.propertyID}`,
                  name: "SignupPolicyScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 6,
              },
              {
                name: self.$t("SignupStep.step7"),
                active: (self.stepwh_signup == 7 && true) || false,
                finish: temp?.step_signup?.step7 || false,
                linkItems: {
                  link: `signup/payment/${self.userToken}/${self.propertyID}`,
                  name: "SignupPaymentScreen",
                  params: { propertyId: self.propertyID },
                },
                step: 7,
              },
            ];
          }
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
        self.gotosignin();
      } finally {
        self.getStepData(self.itemsStep);
        self.initRatePlanTypes();
        self.initPropertyIDData();
      }
    },
    getStepData(items) {
      const self = this;
      self.itemsStep = items;
      self.changePhotoRight();
      // console.log(items)
    },
    changePhotoRight() {
      const self = this;
      var tempLG = localStorage.getItem("language") === "th" ? "th" : "en";
      self.pathrightphoto = `${process.env.VUE_APP_PIC_API}/src/step6-${tempLG}.gif`;
    },
    initPropertyIDData() {
      const self = this;
      var temp = [];
      var tempRoomTypes = [];
      self?.roomTypies?.map((el) => {
        // var indexroomtype = temp.findIndex(p => p.value == el.room_type._id)
        // if (indexroomtype < 0) {
        temp.push({
          _id: el._id,
          showname: `${el.room_name.value} (${el.room_type.value})`,
          name: el.room_name.value,
          value: el.room_name._id,
          roomtype: el.room_type.value,
          roomtypeid: el.room_type._id,
          typename: el.room_type.value,
          roomname: el.room_name.value,
          roomnameid: el.room_name._id,
          customer_normal: el?.customer?.normal || 0,
          customer_max: el?.customer?.max || 0,
        });
        // }
        // if (indexroomtype < 0) {
        tempRoomTypes.push({
          _id: el._id,
          showname: `${el.room_name.value} (${el.room_type.value})`,
          name: el.room_name.value,
          value: el.room_name._id,
          roomtype: el.room_type.value,
          roomtypeid: el.room_type._id,
          roomname: el.room_name.value,
          roomnameid: el.room_name._id,
          customer_normal: el?.customer?.normal || 0,
          customer_max: el?.customer?.max || 0,
          base_rate: "",
          extra_bed_adult: "",
          extra_bed_child: "",
          adult_rate: "",
          child_rate: "",
          hover: false,
        });
        // }
      });
      self.itemsRoomType = temp;
      self.itemsDefaultRoomType = temp;
      // self.addNewRatePlan.newrateplan.roomtypes = tempRoomTypes
      self.setRoomType();
    },
    setRoomType() {
      const self = this;
      const temp = [];
      self?.itemsDefaultRoomType?.map((el) => {
        const tempRoomName =
          self?.addNewRatePlan?.newrateplan?.roomtypes?.findIndex(
            (p) => p.room == el._id
          );
        // console.log('tempRoomName: ', tempRoomName, self?.addNewRatePlan?.newrateplan?.roomtypes, el)
        if (tempRoomName < 0) {
          temp.push(el);
        }
      });
      self.itemsRoomType = temp;
      self.initDefaultRatePlanDatatypies(self.initDataStep.rate_plan_price);
    },
    initDefaultRatePlanDatatypies(item) {
      const self = this;
      var temp = [];
      var tempRoomTypies = [];
      if (item?.length) {
        item?.map((el) => {
          // console.log("el: ", el);
          tempRoomTypies = [];
          el?.rooms?.map((ele) => {
            const tempRoomName = self.itemsRoomType.find(
              (p) => p.value == ele?.room_name?._id
            );
            tempRoomTypies.push({
              _id: ele._id,
              room:
                tempRoomName?._id || typeof ele?.room == "object"
                  ? ele?.room?._id || ""
                  : ele?.room || "",
              room_name:
                typeof ele?.room_name == "object"
                  ? ele?.room_name?._id || ""
                  : ele?.room_name || "",
              base_rate: ele?.base_rate || "",
              extra_bed_adult: ele?.extra_bed_adult || "",
              extra_bed_child: ele?.extra_bed_child || "",
              adult_rate: ele?.adult_rate || "",
              child_rate: ele?.child_rate || "",
            });
          });
          if (el?.rooms?.length)
            temp.push({
              _id: el._id,
              rate_plan:
                typeof el?.rate_plan == "object"
                  ? el?.rate_plan?._id || ""
                  : el?.rate_plan || "",
              per_room: el.per_room,
              per_guest: el.per_guest,
              rooms: tempRoomTypies,
            });
        });
      }
      self.itemsRatePlanData = temp;
      // console.log("itemsRatePlanData: ", temp);
    },
    nextToPaid(num) {
      const self = this;
      const obj = {
        book_in_advance: self?.addPolicyData?.amountday || 0,
        book_in_unintentional: self?.addPolicyData?.protect || true,
        check_in: self?.addPolicyData?.checkin || "",
        check_out: self?.addPolicyData?.checkout || "",
        child: self?.addPolicyData?.allowchild || false,
        pet: self?.addPolicyData?.allowpet || false,
      };
      if (!obj.book_in_advance || obj.book_in_advance == 0 || !obj.check_in) {
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        self.updateStep(obj, num);
      }
    },
    async updateStep(obj, num) {
      const self = this;
      var linkItems = {
        link: `signup/payment/${self.userToken}/${self.propertyID}`,
        name: "SignupPaymentScreen",
        params: { userToken: self.userToken, propertyId: self.propertyID },
      };
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/step6?property_id=${self.propertyID}&next=${1}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          self.stepwh_signup = num;

          self.updateRatePlanBookInAdvancedFunc(linkItems);
        } else {
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        console.log(error?.response?.data?.message || error);
      }
    },
    async changeStep(linkItems) {
      const self = this;
      EventBus.$emit("loadingtillend");
      const obj = {
        book_in_advance: self?.addPolicyData?.amountday || 0,
        book_in_unintentional: self?.addPolicyData?.protect || true,
        check_in: self?.addPolicyData?.checkin || "",
        check_out: self?.addPolicyData?.checkout || "",
        child: self?.addPolicyData?.allowchild || false,
        pet: self?.addPolicyData?.allowpet || false,
      };
      if (!obj.book_in_advance || obj.book_in_advance == 0 || !obj.check_in) {
        EventBus.$emit("endloading");
        swal(
          self.$t("Alert.warn_title"),
          self.$t("Alert.fillinfo"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      } else {
        try {
          const res = await self.axios.put(
            process.env.VUE_APP_API +
              `/property/step6?property_id=${self.propertyID}&next=${0}`,
            obj,
            {
              headers: {
                Authorization:
                  self?.userToken ||
                  localStorage?.getItem("Token") ||
                  self?.$store?.state?.resSignUpData?.access_token,
                "X-Language-Code":
                  localStorage?.getItem("language") ||
                  self?.$store?.state?.language,
              },
            }
          );
          if (res.status == 200) {
            EventBus.$emit("endloading");
            EventBus.$emit("changePathname", linkItems);
          } else {
            EventBus.$emit("endloading");
            swal(
              self.$t("Alert.warn_title"),
              self.$t("Alert.tryagain"),
              self.$t("Alert.warn_label"),
              {
                button: false,
                timer: 3000,
              }
            );
          }
        } catch (error) {
          EventBus.$emit("endloading");
          console.log(error?.response?.data?.message || error);
          swal(
            self.$t("Alert.warn_title"),
            error?.response?.data?.message || self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      }
    },
    async updateRatePlanBookInAdvancedFunc(linkItems) {
      const self = this;
      for (let i = 0; i < self.itemsRatePlanData.length; i++) {
        const findRatePlan = self.itemsRatePlan?.find(
          (p) => p.value == self.itemsRatePlanData[i].name
        );
        const obj = {
          _id: self.itemsRatePlanData[i]._id,
          advance_booking: self.addPolicyData.amountday,
          age_of_voucher: {
            length: 365,
            expire_date: null,
          },
        };
        if (findRatePlan?.name == self.$t("SignupStep3.room_with_breakfast")) {
          obj.food_service = "breakfast";
        }
        self.updateRatePlanTypes(obj, obj?._id);

        if (i == self.itemsRatePlanData.length - 1) {
          EventBus.$emit("loadingPage");
          EventBus.$emit("changeStep", self.stepwh_signup);
          self.$store.dispatch("changeSignupStep", {
            signupstep: self.stepwh_signup,
          });
          EventBus.$emit("changePathname", linkItems);
        }
      }
    },
    async updateRatePlanTypes(obj, rateplanId) {
      const self = this;
      // console.log('obj: ', obj, 'rateplanID: ', rateplanId, num)
      try {
        const res = await self.axios.put(
          process.env.VUE_APP_API +
            `/property/rate_plan_price?rate_plan_price_id=${rateplanId}`,
          obj,
          {
            headers: {
              Authorization:
                self?.userToken ||
                localStorage?.getItem("Token") ||
                self?.$store?.state?.resSignUpData?.access_token,
              "X-Language-Code":
                localStorage?.getItem("language") ||
                self?.$store?.state?.language,
            },
          }
        );
        if (res.status == 200) {
          EventBus.$emit("endloading");
          EventBus.$emit("loadingPage");
        } else {
          EventBus.$emit("endloading");
          swal(
            self.$t("Alert.warn_title"),
            self.$t("Alert.tryagain"),
            self.$t("Alert.warn_label"),
            {
              button: false,
              timer: 3000,
            }
          );
        }
      } catch (error) {
        EventBus.$emit("endloading");
        console.log(error?.response?.data?.message || error);
        swal(
          self.$t("Alert.warn_title"),
          error?.response?.data?.message || self.$t("Alert.tryagain"),
          self.$t("Alert.warn_label"),
          {
            button: false,
            timer: 3000,
          }
        );
      }
    },
    goback() {
      const self = this;
      var item = {
        link: `signup/pictures/${self.userToken}/${self.propertyID}`,
        name: "SignupPicturesScreen",
        params: { userToken: self.userToken, propertyId: self.propertyID },
      };
      EventBus.$emit("changePathname", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";

.moving-left {
  /* height: 100px; */
  /* position: relative; */
  transition: transform 0.3s ease-in-out;
  transform: translateX(0px);
  /* width: 100px; */
}

.moving-left:hover {
  transform: translateX(-10px);
}

.hover_numberbtn:hover {
  border: solid 1px transparent;
  color: #fff !important;
  background-image: linear-gradient(#f9f9f9, #f9f9f9),
    radial-gradient(circle at top left, #f00, #3020ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
}

.btn.gl-bt {
  overflow: hidden;
  border: 0;
  background-color: var(--pink);
  padding: 0 0.625rem;
}

.btn.gl-bt:hover {
  background-color: #652b80;
}

.btn.gl-bt::before {
  top: calc(var(--y, 0) * 1px - 50px);
  left: calc(var(--x, 0) * 1px - 50px);
  --size: 0;
  content: "";
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #e8107f, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
}

.gl-bt:hover:before {
  --size: 350px;
}

.border-pink-focus {
  // background-color: #fff;
  border-color: var(--pink) !important;
}

.v-input__slot {
  border: 1px solid var(--border-gray) !important;
}

.btn-outline:hover span {
  color: #fff !important;
}

.btn-outline-active {
  background-color: var(--pink) !important;
}
.btn-outline-active span {
  color: #fff !important;
}
.navtop-canclick {
  cursor: pointer !important;
}

.btn[disabled] {
  height: fit-content !important;
}
</style>

<style>
@media (min-width: 768px) and (max-width: 1024px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 7.5rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
@media (min-width: 321px) and (max-width: 767px) {
  .siq_bR {
    width: 4.5rem !important;
    height: 3rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 4.5rem !important;
    height: 3rem !important;
    object-fit: contain !important;
  }
}
@media (max-width: 320px) {
  .siq_bR {
    width: 3.5rem !important;
    height: 2rem !important;
    bottom: 6rem !important;
    right: 0.625rem !important;
  }

  #zsiq_float img {
    width: 3.5rem !important;
    height: 2rem !important;
    object-fit: contain !important;
  }
}
</style>
